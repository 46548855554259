.event-reminder{
  padding: 20px;
  border-radius: $outer-box-border-radius;
  // background-color: rgba(28, 27, 25, 0.3);
  // border: 1px solid rgba(28, 27, 25, 0.3);
  // backdrop-filter: blur(20px);
  // -webkit-backdrop-filter: blur(20px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  column-gap: 15px;
  max-width: 600px;
  box-sizing: border-box;
  width: 100%;
  position: relative;

  &.in-request {
    border: 1px solid $harmonies;
    max-width: unset;
    background-color: rgba(28, 27, 25, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 20px;
    flex-direction: row;

    & > .event-reminder__banner{
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
    }
    
    & > .exit-event{
      display: none;
    }
  }


  &:not(.in-request) {
    & .event-reminder__h4{
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 4px;
      text-align: right;
    }
    & .event-reminder__label{
      font-size: 12px;
      line-height: 14px;
      text-align: right;
    }
    & > .exit-event{
      left: 20px;
      pointer-events: all;
    }
  }

  @media screen and (max-width: 500px) {
    border-radius: $outer-box-mob-border-radius;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }
}

.event-reminder__banner{
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  @media screen and (max-width: 500px) {
    border-radius: 8px;
  }
}