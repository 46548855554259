.events{
  grid-column: 1 / span 12;

  @media screen and (min-width: 801px) {
    grid-column: 1 / span 6;
  }
  @media screen and (min-width: 1281px) {
    grid-column: 2 / span 6;
  }
}

.events-section:not(:last-of-type) {
  padding-bottom: 40px;

  @media screen and (min-width: 1281px) {
    padding-bottom: 60px;
  }
}

.event {
  grid-column: span 6;
  background-color: rgba(28, 27, 25, 0.6);
  border-radius: 15px;
  padding: 10px;
  border: 1px solid $harmonies;
  transition: background-color .35s ease;
  position: relative;
  cursor: pointer;

  @media (hover: hover) {
    &:hover{
      background-color: rgba(28, 27, 25, .9);
    }
  }

  &.bigger{
    @media screen and (max-width: 500px) {
      grid-column: span 12;
      padding: 20px;
      border-radius: 20px;

      & > .event__cancelled,
      & > .event__going{
        top: 40px
      }

      & .event__h3{
        font-size: 18px !important;
        line-height: 22px !important;
      }
      & .eyebrow{
        font-size: 12px !important;
        line-height: 14px !important;
        padding-bottom: 5px !important;
      }
    }
  }

  @media screen and (min-width: 1281px) {
    padding: 20px;
    border-radius: 20px;
  }
}

.event__banner {
  border-radius: 10px;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.event__info {
  padding-top: 15px;
  padding-bottom: 5px;

  @media screen and (min-width: 1281px) {
    padding-bottom: 0;
  }
}

.event__cancelled{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: $red;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  place-items: center;
  padding: 5px 16px;
  z-index: 9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media screen and (max-width: 500px) {
    top: 20px
  }
}

.event__going{
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: $green;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  place-items: center;
  padding: 5px 16px;
  z-index: 9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media screen and (max-width: 500px) {
    top: 20px
  }
}

.event-details__cancelled{
  background-color: $red;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.event-details__going{
  background-color: $green;
  color: $white;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}