header {
  height: 80px;
  background-color: rgba(28, 27, 25, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: background-color .35s ease;

  &.blured {
    background-color: rgba(28, 27, 25, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  &.opened {
    background-color: $primary-bg-color;
    z-index: 9999;

    & .hamburger{
      &::before{
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
      &::after{
        width: 100%;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  @media screen and (min-width: 1281px) {
    height: 140px;
  }
}

.exit-event{
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > img,
  & > svg{
    margin-right: 10px;
    margin-bottom: 1px;
  }

  & > .label--gray {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    left: 20px;
  }
}

.header__back {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: 501px) {
    left: 40px;
  }
  @media screen and (min-width: 1281px) {
    left: 60px;
  }
}

.hamburger{
  width: 28px;
  height: 13px;
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    left: 20px;
  }

  &::before,
  &::after{
    content: '';
    position: absolute;
    left: 0;
    background-color: $primary-text-color;
    height: 1px;
    transform-origin: center;
    transition: transform .35s ease, top .35s ease, width .35s ease;
  }

  &::before{
    top: 0;
    width: 100%;
  }
  &::after{
    bottom: 0;
    width: 50%;
  }
}

.sidebar__menu{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-bg-color;
  transition: transform .35s ease;
  position: fixed;
  top: 140px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9990;
  overflow-y: auto;
  transform: translateX(-100%);
  padding-top: 10px;
  padding-bottom: 10px;

  &.opened{
    transform: translateX(0);
  }

  @media screen and (max-width: 1280px) {
    top: 80px;
    padding-bottom: 0;
  }
}

.menu-box{

  & > h3:not(:last-of-type) {
    margin-bottom: 5px;
  }
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.socials{
  & > a:not(:first-of-type) {
    margin-left: 10px;
  }
}

.sidebar__links{
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (max-width: 500px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.sidebar__link{
  color: $white;
  font-size: 16px;
  line-height: 20px;

  &:not(:first-of-type) {
    padding-top: 10px;
  }
}