.maintaince{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.maintaince__h1{
  font-weight: 700;
  font-size: 70px;
  line-height: 84px;
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;

  @media screen and (max-width: 800px) {
    font-size: 50px;
    line-height: 64px;
  }
}

.maintaince__p{
  max-width: 520px;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  padding-bottom: 40px;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 28px;
  }
}