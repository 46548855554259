@import '../setup/style.scss';
@import '../components/buttons';
@import '../components/header';
@import '../components/footer';
@import '../components/inputs';
@import '../components/event';
@import '../components/content';
@import '../components/popup';
@import '../components/event-details';
@import '../components/controller';
@import '../components/event-reminder';
@import '../components/loader';
@import '../components/canvas';
@import '../components/scanner';
@import '../components/messages';
@import '../components/language-switcher';
@import '../components/club-suspended';
@import '../components/maintaince';
@import '../components/legal-content';
@import '../components/request-a-table';
@import '../components/prompt';
@import '../components/cookie-consent';

.no-events__p{
  padding-bottom: 200px;
}