
.prompt-wrapper{
  position: fixed;
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(50px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  transition: opacity .35s ease;
  opacity: 0;
  pointer-events: none;

  &.opened{
    opacity: 1;
    pointer-events: all;
  }

  & > h2{
    text-align: center;
  }
}

.prompt-buttons{
  width: 100%;
  max-width: 500px;
}