.content {
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 80px;
  box-sizing: border-box;
  z-index: 99;
  transition: opacity .35s ease;
  overflow-y: auto;

  @media screen and (min-width: 1281px) {
    padding-top: 140px;
  }
}

.closed{
  opacity: 0;
  pointer-events: none;
}