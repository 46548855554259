footer{
  padding-top: 40px;
  grid-column: 1 / span 12;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 801px) {
    grid-column: 1 / span 12;
    padding-top: 100px;
  }
  @media screen and (min-width: 1281px) {
    grid-column: 2 / span 10;
  }
}

.footer__links{
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer__link{
  font-weight: 300;
  color: $white;
  font-size: 14px;
  line-height: 18px;

  &:not(:first-of-type) {
    padding-left: 20px;
  }
}

.copyright{
  color: rgba(255,255,255,.5);
  text-align: center;
  font-weight: 300;
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: 12px;

  @media screen and (min-width: 1281px) {
    padding-top: 30px;
  }
}