.loader-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper--fullscreen{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.loader.small {
  width: 19px;
  height: 19px;

  &::after {
    width: 11px !important;
    height: 11px !important;
    margin: 1px !important;
    border-width: 3px !important;
  }
}

.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 1px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}