.request-a-table{
  padding-top: 40px;
  grid-column: 1 / span 12;

  @media screen and (min-width: 801px) {
    grid-column: 1 / span 12;
  }
  @media screen and (min-width: 1281px) {
    grid-column: 3 / span 8;
  }
}

.request__inputs{
  padding-top: 20px;
}

.request__status{
  padding: 20px;
  border-radius: $outer-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid $harmonies;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    border-radius: $outer-box-mob-border-radius;
    padding: 10px;
    margin-top: 10px;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }
}