.cookie-consent__wrapper{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  padding: 40px;
  box-sizing: border-box;
  background-color: $bokara;
  z-index: 999999;
  -webkit-box-shadow: -10px -10px 50px 0px rgba(28,27,25,0.5);
  -moz-box-shadow: -10px -10px 50px 0px rgba(28,27,25,0.5);
  box-shadow: -10px -10px 50px 0px rgba(28,27,25,0.5);
  border-top: 1px solid $harmonies;
  border-left: 1px solid $harmonies;

  @media screen and (max-width: 500px) {
    max-width: unset;
    padding: 30px;
    border-left: none;
  }
}

.cookie-consent__title{
  padding-bottom: 15px;
}

.cookie-consent__text{
  padding-bottom: 10px;

  & > a{
    text-decoration: underline !important;
  }
}