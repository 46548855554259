.controller{
  position: fixed;
  bottom: 20px;
  z-index: 15;
  max-width: 600px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 500px) {
    transform: none;
    width: auto;
    left: 20px;
    right: 20px;
  }
}

.form{
  padding: 20px;
  border-radius: $outer-box-border-radius;
  background-color: rgba(28, 27, 25, 0.3);
  border: 1px solid rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  @media screen and (max-width: 500px) {
    border-radius: $outer-box-mob-border-radius;
    padding: 10px;
  }
  @media screen and (max-height: 550px) {
    padding: 10px;
  }
}

.controls{
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  opacity: 1;
  transition: opacity .35s ease;
  position: relative;

  & > .action{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  & .control-text{
    font-size: 14px;
    margin: 0 10px;
    color: rgba(255, 255, 255, .75);
  }

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.active-table {
  font-size: 14px;
  margin: 0 10px;
  color: $primary-text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.min-bottles-badge{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.step{
  padding-bottom: 20px;
}

.personal {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height .35s ease;

  &[data-opened=true] {
    max-height: 200px;
  }
}

.step-back {
  position: absolute;
  right: 0;
  top: -5px;
  cursor: pointer;
}

.PhoneInput{
  position: relative !important;
}

.PhoneInputCountry{
  position: absolute !important;
  width: 75px !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 0 !important;
  left: 1px !important;
}

.PhoneInputInput{
  padding-left: 75px !important;
}

.PhoneInputCountrySelectArrow{
  color: $white !important;
}

.PhoneInputCountryIcon{
  box-shadow: 0 0 0 .5px #fff, inset 0 0 0 .5px #fff !important;
  margin: 0 3px !important;
}

.reservation-status{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.reservation-status__h2{
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  color: $primary-text-color;
  padding-bottom: 0 !important;
}

.reservation-status__h6{
  margin-bottom: 30px;
  text-align: center;
  color: $secondary-text-color;
}

.reservation-status__link{
  color: $white !important;
  font-weight: bold;
  cursor: pointer;
}

.reservation-status__grid{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  column-gap: 10px;
}