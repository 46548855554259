.button {
  width: -webkit-fill-available;
  height: 45px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  border: 1px solid $button-bg-color;
  border-radius: $inner-box-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $button-bg-color;
  color: $button-text-color;
  transition: opacity .35s ease, background-color .35s ease, border-color .35s ease, color .35s ease;
  opacity: 1;
  margin-top: 20px;

  &.no-margin{
    margin-top: 0 !important;
  }

  &.not-ready{
    background-color: #333 !important;
    border-color: transparent !important;
    pointer-events: none !important;
  }

  &.disabled{
    background-color: #454545;
    border-color: #454545;
    color: $secondary-text-color;
    pointer-events: none;
  }

  @media screen and (max-width: 500px) {
    border-radius: $inner-box-mob-border-radius;
  }

  @media (hover: hover) {
    &:hover {
      opacity: .85;
    }
  }

  & > img,
  & > svg {
    margin-left: 10px;
    padding-bottom: 1px;
    padding-top: 0;
  }

  &--invert {
    @extend .button;
    background-color: transparent;
    color: $button-bg-color;
    transition: background-color .35s ease, color .35s ease;

    @media (hover: hover) {
      &:hover {
        background-color: $button-bg-color;
        color: $button-text-color;
        opacity: 1;
      }
    }
  }

  &--back {
    @extend .button;
    flex-direction: row-reverse;

    & > img,
    & > svg {
      margin-left: 0px;
      margin-right: 10px;
      padding-top: 1px;
      padding-bottom: 0;
    }
  }

  &.col-6 {
    grid-column: span 6;
  }
}