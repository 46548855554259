.scanner__wrapper{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 27, 25, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 9999;
  padding: 60px;
  transition: opacity .35s ease;
  pointer-events: none;
  opacity: 0;
  overflow-y: auto;

  @media screen and (max-width: 800px) {
    padding: 30px 20px;
  }

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.scanner__content{
  display: flex;
  flex-direction: column;
}

.scanner__title{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $primary-text-color;
  margin-top: 100px;
  margin-bottom: 30px;

  @media screen and (max-width: 500px) {
    margin-top: 40px;
  }
}

.scanner__countdown{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $primary-text-color;
  margin-bottom: 50px;
}

.scanner__number{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $primary-text-color;
  margin-bottom: 40px;
}

.scanner__numbers{
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 10px;
  max-width: 310px;
  align-self: center;
}

.scanner__input{
  width: 70px;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  text-align: center;
  font-family: $primary-font;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: $primary-text-color;
  padding: 0 !important;
}

.scanner__code-not-received{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $primary-text-color;
  padding-top: 40px;
  cursor: pointer;
}

.scanner__whatsapp-explanation__title{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: $primary-text-color;
  margin-top: 100px;

  @media screen and (max-width: 500px) {
    margin-top: 40px;
  }
}

.scanner__whatsapp-explanation__section{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: $primary-text-color;
  margin-top: 40px;
}

.scanner__whatsapp-explanation__text{
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $secondary-text-color;
  margin-top: 20px;

  & > a {
    color: $primary-text-color;
    font-weight: bold;
  }
}

.scanner__whatsapp-explanation__again{
  margin-top: 45px;
}