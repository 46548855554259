.event-details{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-details__box{
  padding-bottom: 40px;
}

.event-details__banner{
  margin: 30px 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  border-radius: $outer-box-border-radius;

  @media screen and (max-width: 500px) {
    margin: 20px 0;
  }
}

.event-details__when{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}

.event-details__icon-box{
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img{
    margin-right: 10px;
  }

  &:not(:first-of-type){
    margin-left: 20px;
  }
}

.event-details__single{
  grid-column: span 6;

  &.big{
    grid-column: span 12;
  }
}

.event-details__single-name{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #EEEEEE;
  margin-bottom: 5px;
}

.reservation-available{
  text-align: center;
  margin-top: 30px;
}