html, body {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  background-color: #1C1B19; // $primary-bg-color;
  font-family: $primary-font;
  overflow: hidden;
}

.logo {
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: $primary-text-color;
  display: flex;

  & > img{
    max-width: 150px;

    @media screen and (max-width: 1280px) {
      max-width: 110px;
    }
  }

  &--footer > img{
    max-width: 80px;
  }
}

// ----------------------
// Grid
// ----------------------

.container {
  padding-left: 60px;
  padding-right: 60px;
  
  @media screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.maxw{
  max-width: 1440px;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 20px;
  row-gap: 20px;

  @media screen and (max-width: 500px) {
    column-gap: 10px;
  }

  &--2{
    @extend .grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &.row-30 {
    row-gap: 30px;
  }
}

// ----------------------
// Typography
// ----------------------

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: $primary-text-color;
  margin: 0;
  padding: 30px 0;

  @media screen and (min-width: 1281px) {
    padding: 60px 0;
    font-size: 38px;
    line-height: 44px;
  }
}

h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $primary-text-color;
  margin: 0;
  padding-bottom: 20px;

  @media screen and (min-width: 1281px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 30px;
  }
}

h3 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: $primary-text-color;
  margin: 0;

  @media screen and (min-width: 1281px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: $primary-text-color;
  padding-bottom: 5px;
  margin: 0;
}

h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: $primary-text-color;
}

h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  color: #DDD;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $secondary-text-color;
  margin: 0;
}

.title {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: $primary-text-color;

  @media screen and (max-width: 500px)  {
    font-size: 24px;
    line-height: 28px;
  }
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  color: #EEE;

  &--gray {
    @extend .label;
    color: $secondary-text-color;
  }
}

.eyebrow {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $eyebrow-text-color;
  margin: 0;
  padding-bottom: 3px;

  @media screen and (min-width: 1281px) {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 5px;
  }
}

a {
  cursor: pointer;
  text-decoration: none !important;
  color: inherit;
}

.underline {
  text-decoration: underline !important;
}

// ----------------------
// universal classes
// ----------------------

picture {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: $primary-bg-color;

  &:not(.loaded):before{
    content: '';
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, $primary-bg-color 0%, $harmonies 50%, $primary-bg-color 100%);
    animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
  
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
    opacity: 0;

    &.fadeIn{
      transition: opacity .35s ease;
    }
  }

  &.loaded > img{
    opacity: 1;
  }
}

@keyframes load {
  from {
      left: -100%;
  }
  to   {
      left: 100%;
  }
}

.img-180 {
  transform: rotate(180deg);
}

// ----------------------
// hovers
// ----------------------

.hover--opacity {
  transition: opacity .35s ease;
  opacity: 1;

  @media (hover: hover)  {
    &:hover {
      opacity: .85;
    }
  }
}

.hover--underline {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    height: 1px;
    background-color: $primary-text-color;
    transition: right .35s ease;
  }

  @media (hover: hover)  {
    &:hover::after {
      right: 0px;
    }
  }
}

.hover--translate-right {
  transition: transform .35s ease;
  transform: translateX(0);

  @media (hover: hover)  {
    &:hover {
      transform: translateX(3px);
    }
  }
}

.hover--translate-left {
  transition: transform .35s ease;
  transform: translateX(0);

  @media (hover: hover)  {
    &:hover {
      transform: translateX(-3px);
    }
  }
}

// ----------------------
// backlink
// ----------------------

.back-link{
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > img,
  & > svg{
    margin-right: 10px;
    margin-bottom: 1px;
  }
}