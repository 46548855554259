.language-switcher{
  z-index: 9999;
  cursor: pointer;
  padding: 8px 12px;
  box-sizing: border-box;
  background-color: #383838;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 0;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0;
  width: unset;

  @media screen and (max-width: 800px) {
    padding: 4.5px 7px;
  }

  @media screen and (max-width: 500px) {
    right: 20px;
  }

  & > .language__selected{
    font-size: 12px;
    color: $primary-text-color;
    margin-left: 7px;
    text-transform: uppercase;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.language-switcher__arrow{
  margin-left: 7px;

  @media screen and (max-width: 800px) {
    margin-left: 5px;
  }
}

.languages{
  position: absolute;
  background-color: #383838;
  border-radius: 5px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity .35s ease;
  top: calc(100% + 10px);
  bottom: auto;
  right: 0;
  left: auto;

  &.opened{
    opacity: 1;
    pointer-events: all;
  }
}

.language{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $primary-text-color;
  padding: 10px 15px;
  transition: background-color .35s ease, color .35s ease;

  &.active{
    background-color: $white;
    color: $bokara;
  }

  @media (hover: hover) {
    &:hover{
      background-color: $white;
      color: $bokara;
    }
  }

  &:not(:last-of-type){
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  & > img{
    width: 16px
  }

  & > span{
    margin-left: 10px;
  }
}